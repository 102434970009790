<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-button
            @click="dialogVisible =! dialogVisible"
            type="primary"
            v-if="!loading"
        >
          {{ $t('system.add') }} {{ $t('system.form_json') }}
        </el-button>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <el-table
            :data="model"
            border
            fit
            highlight-current-row
            row-key="id"
            style="width: 100%;"
            v-loading="loading"
        >
          <el-table-column
              :label="$t('json.filename')"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.filename }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('json.path')"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.path }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('json.type')"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.type }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('json.container_name')"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.container_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('json.active')"
          >
            <template slot-scope="scope">
              <el-checkbox
                  v-model="scope.row.active"
                  v-on:change.native="changeActive(scope.row)"
              >
              </el-checkbox>
            </template>
          </el-table-column>

          <el-table-column
              :label="$t('json.actions')"
              width="200"
          >
            <template slot-scope="scope">
              <el-button
                  @click="redirectTo(scope.row)"
                  size="mini"
                  type="info"
              >
                {{ $t('system.edit') }}
              </el-button>

              <el-button
                  @click="removeElement(scope.row)"
                  size="mini"
                  type="danger"
                  v-if="userModel != 'SaaSUser'"
              >
                {{ $t('system.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <json-form
        @close="close"
        @saved="refresh"
        v-if="dialogVisible"
    ></json-form>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import swal from 'sweetalert2';
import {mapState} from 'vuex';
import JsonForm from './Form.vue';
import notification from '../../notification/notify';

export default {
  name: 'JsonIndex',
  components: {
    JsonForm,
  },
  beforeMount() {
    if (this.$store.state.userModel === 'SaaSUser') {
      this.create = '/saas/json/create';
    }
    this.fetchData();
  },
  mounted() {
  },
  computed: {
    ...mapState([
      'userModel',
    ]),
  },
  watch: {},
  data() {
    return {
      model: [],
      source: 'json',
      create: '/json/create',
      resizable: false,
      adaptive: false,
      draggable: false,
      showModal: false,
      loading: true,
      dialogVisible: false,
    };
  },
  methods: {
    fetchData() {
      let vm = this;
      let source = vm.source;
      if (vm.$store.state.userModel === 'SaaSUser') {
        source = `saas/json`;
      }
      vm.loading = true;
      axios.get(`${source}`)
          .then(function (response) {
            Vue.set(vm.$data, 'model', response.data.model);
            vm.loading = false;
          })
          .catch(function (error) {
            vm.loading = false;
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.message),
                'error');
          });
    },
    removeElement: function (element) {
      let vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then(function (resp) {
        if (resp.value) {
          axios.delete(`json/${element.id}`)
              .then(function (response) {
                if (response.data.deleted) {
                  notification.notify(
                      vm.$t('notify.success'),
                      vm.$t('system.remove_success'),
                      'success');
                  vm.fetchData();
                } else {
                  notification.notify(
                      vm.$t('notify.error'),
                      vm.$t('system.remove_error'),
                      'error');
                }
              })
              .catch(function (error) {
                notification.notify(
                    vm.$t('notify.error'),
                    vm.$t(error.response.data.error),
                    'error');
              });
        }
      }, function (dismiss) {
      });
    },
    close() {
      this.dialogVisible = false;
    },
    refresh() {
      Vue.nextTick(() => {
        this.fetchData();
      });
    },
    redirectTo: function (element) {
      if (this.$store.state.userModel === 'SaaSUser') {
        this.$store.state.filePath = element.path;
        this.$router.push(`/saas/json/` + 0);
      } else {
        this.$router.push(`/system_settings/json/` + element.id);
      }
    },
    changeActive: function (element) {
      let vm = this;
      axios.post(`json/${element.id}/changeActive`, {element})
          .then(function (response) {
            if (response.data.updated) {
              notification.notify(
                  vm.$t('notify.success'),
                  vm.$t(response.data.message),
                  'success');
            } else {
              element.active = !element.active;
              notification.notify(
                  vm.$t('notify.error'),
                  vm.$t(response.data.message),
                  'error');
            }
          })
          .catch((error) => {
            element.active = !element.active;
            console.log(error);
          });
    },
  },
};
</script>

<style>
.wrap-link {
  word-wrap: break-word;
}
</style>
