<template>
  <el-dialog
      :before-close="close"
      class="issue-modal"
      top="60px"
      visible
      width="700px"
  >
    <span
        class="dialog-header"
        slot="title"
    >
      <span class="el-dialog__title">{{ $t('system.add') }}{{ $t('system.form_json') }}</span>
    </span>
    <div class="dialog-content">
      <el-form
          class="px-3 w-100"
          label-position="top"
      >
        <div class="row">
          <div class="col-7">
            <el-form-item :label="$t('system.name')">
              <el-input v-model="db_name.filename" />
            </el-form-item>
          </div>
          <div class="col-5">
            <el-form-item :label="$t('system.container_name')">
              <el-select
                  class="w-100"
                  filterable
                  v-model="db_name.container_name"
              >
                <el-option
                    :key="container.id"
                    :value="container.id"
                    v-for="container in containers"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12">
            <el-form-item :label="$t('system.file')">
              <el-upload
                  :auto-upload="false"
                  :limit="100"
                  :on-change="onChange"
                  :thumbnail-mode="false"
                  accept="application/json"
                  action=""
                  class="file--upload"
                  drag
                  list-type="text"
                  multiple
                  type="files"
                  v-model="db_name.files"
                  with-credentials
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="box-button">
        <el-button
            type="primary"
            v-on:click="onSave"
        >{{ $t('system.save') }}
        </el-button>
        <el-button @click="close">{{ $t('system.cancel') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'JsonForm',
  data() {
    return {
      containers: [
        {
          id: 'issue',
          value: 'Issue',
        },
        {
          id: 'project',
          value: 'Project',
        },
      ],
      db_name: {
        container_name: '',
        filename: '',
        files: [],
      },
      backend_errors: {},
      schema: {
        fields: [
          {
            'type': 'Input',
            'inputName': 'name',
            'label': 'name',
            'db_name': 'filename',
            'required': true,
          },
          {
            'type': 'Select',
            'inputName': 'container_name',
            'label': 'container_name',
            'db_name': 'container_name',
            'multiple': false,
            'required': true,
            'options': [
              {
                'id': 'issue',
                'name': 'Issue',
                'value': 'Issue',
                'label': 'Issue',
                'disabled': false,
              },
              {
                'id': 'project',
                'name': 'Project',
                'value': 'Project',
                'label': 'Project',
                'disabled': false,
              },
            ],
          },
          {
            'type': 'Upload',
            'inputName': 'files',
            'label': 'files',
            'db_name': 'files',
            'styleClasses': '',
            'multiple': true,
            'withCredentials': true,
            'showFileList': true,
            'drag': true,
            'thumbnailMode': false,
            'listType': 'text',
            'autoUpload': false,
            'disabled': false,
            'limit': 100,
            'uploadText': 'Wrzuć plik',
            'tipText': 'Przyjmuję tylko pliki',
            'required': true,
            'accept': 'application/json',
          },
        ],
      },
      method: 'post',
      store: 'json',
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onSave() {
      if (this.$store.state.userModel === 'SaaSUser') {
        this.store = 'saas/json';
        this.db_name.userModel = this.$store.state.userModel;
      }
      Global.save(this);
      // let formData = new FormData();
      // formData.append('container_name', this.form.container_name);
      // formData.append('filename', this.form.filename);
      // this.form.files.forEach()
      // formData.append('files[]', this.form.container_name);
      // axios.post(this.store, formData, {headers: {'Content-Type': `multipart/form-data`}})
    },
    onChange(file, fileList) {
      // this.addToGallery(file, fileList)
      let isJPG = true;
      isJPG = file.raw.type === 'application/json';
      if (!isJPG) {
        this.uploadFiles = fileList.map(item => {
          if (item.uid === file.uid) {
            fileList.splice(fileList.indexOf(file), 1);
          }
        });
        notification.notify(
            this.$t('notify.error'),
            this.$t('message.not_accept_file_type'),
            'error');
      } else {
        this.getUpload(file, fileList);
      }
    },
    getUpload: function (file, fileList) {
      this.uploadFiles = fileList.map(item => {
        if (typeof (item.disabled) === 'undefined' || !item.disabled) {
          item.percentage = 50;
          item.status = 'ready';

          return item.raw;
        }
      });
      this.db_name.files = this.uploadFiles;
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
@import '../../static/css/dynamicTable.scss';

.padding-0-20px {
  padding: 0 20px;
}

.form-pb-0 {
  .form-group {
    padding-bottom: 0 !important;
  }
}

.file--upload {
  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.fieldUpload--list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
  }
}
</style>
